@import 'scss/typography';
@import 'scss/components';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

* {
  box-sizing: border-box;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.8em 0 !important;
  border-top-width: 4px;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar {
  background-color: box-color(primary);
}

.mat-mdc-tab-group.workspace__container-expanded__tabs.mat-primary .mat-ink-bar {
  background-color: box-color(primary-on-contrast-background);
}

.workspace__container {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label {
    margin-right: 9px;
  }

  .mat-ink-bar {
    position: relative;
    top: -2px;
    display: block;
    margin-left: 5px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-labels {
  justify-content: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label:not(.mat-tab-label-active) {
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: 142px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: box-color(grey);
    margin-left: 8px;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label,
.mat-tab-label-active {
  color: box-color(white);
}

.mat-mdc-tab-group {
  height: calc(100% - 100px);

  &::-webkit-scrollbar {
    width: 4px;
    height: 132px;
  }

  &::-webkit-scrollbar-track {
    background: box-color(background-contrast);
  }

  &::-webkit-scrollbar-thumb {
    background: box-color(grey-darker);
    border-radius: 10px;

    &:hover {
      background: box-color(grey-lighter);
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-body .mat-tab-body-content {
  max-height: 350px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: box-color(background-contrast);
  }

  &::-webkit-scrollbar-thumb {
    background: box-color(grey-darker);
    border-left: 6px solid box-color(background-contrast);
    border-radius: 0;

    &:hover {
      background: box-color(grey-lighter);
    }
  }
}

.blur-background {
  animation: fade 300ms;
  background-color: box-color(black-overlay);
  backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;

  @-webkit-keyframes fade {
    from {
      opacity: 0;
      backdrop-filter: blur(0);
    }
    to {
      opacity: 1;
      backdrop-filter: blur(6px);
    }
  }

  @keyframes fade {
    from {
      opacity: 0;
      backdrop-filter: blur(0);
    }
    to {
      opacity: 1;
      backdrop-filter: blur(6px);
    }
  }
}

.home-warning-snackbar {
  background-color: box-color(negative);
  color: box-color(white);
  max-width: 80vw !important;
}

.hidden {
  display: none !important;
}

.news-sidebar-container {
  .mat-drawer-content {
    overflow: visible;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: box-color(white);
}

::-webkit-scrollbar-thumb {
  background: box-color(grey-lighter);
  border-radius: 10px;

  &:hover {
    background: box-color(grey-darker);
  }
}

.contextual-help-side-panel-backdrop {
  top: 65px !important;
  background: rgba(0, 0, 0, 0.32);
}

.contextual-help-side-panel {
  position: fixed !important;

  // panel should be all way to the bottom
  bottom: 0;

  // 637px: That's the exact width of slide-in right panels according to the design
  right: -637px;

  // sliding-in on enter. no animation on exit.
  animation: slide 0.8s forwards;

  // We're waiting for modal to be fully rendered to start animation - it must come after 'animation' prop.
  animation-delay: 0.1s;

  .mat-mdc-dialog-container .mdc-dialog__surface {
    overflow-y: hidden;
    border-radius: 0;
    padding: 10px 10px 50px 50px;
    box-shadow: unset !important;
  }
}

.add-logo-dialog-panel {
  .mat-mdc-dialog-container {
    overflow: hidden;
    border-radius: 12px;
    padding: 0;
  }
}

@keyframes slide {
  100% {
    right: 0;
  }
}

.questionnaire-modal {
  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
  mat-dialog-container {
    padding: 0;
    overflow: hidden;
    max-width: unset; // to fix briliant styling of market-admin
  }
}
.welcome-popup-panel {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 24px;
  }
}

