@import 'src/scss/variables';

.user-menu {
  &.mdc-menu-surface {
    padding: 20px 35px;
  }

  &__section {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:not(:first-of-type) {
      margin-top: 25px;
    }

    &-header {
      @include typography_caption;
      font-weight: 500;
      color: box-color(grey);
      margin-bottom: 15px;
    }

    &-element,
    a {
      @include typography_body_1;
      transform: perspective(1px) scale(1.04);
      cursor: pointer;
      color: box-color(grey-darkest);
      text-decoration: none;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &.disabled {
        @include disabled;
      }
    }

    &-button {
      padding: 0;
      border: none;
      background: none;

      &:disabled {
        @include disabled;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.custom-tooltip {
  width: 150px;
  font-size: 11px;
  text-align: center;
  line-height: 15px;
  background: box-color(background-contrast);
  color: box-color(grey-lighter);

  border: 1px solid box-color(grey);
  border-radius: 6px;
}

.mat-mdc-snack-bar-container {
  &.info-snackbar {
    background-color: box-color(background-contrast);
  }

  &.error-snackbar {
    background-color: box-color(negative);
    color: box-color(white);

    .snackbar__container {
      .snackbar__action {
        color: box-color(white);
      }
    }
  }
}

.workspace__spinner {
  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: box-color(primary-on-contrast-background);
  }
}
