@import 'node_modules/@mads/wm-ng-components/lib/scss/colors.scss';

@mixin dropdown-element {
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: box-color(background-secondary);
  }
}

@mixin disabled {
  color: box-color(grey-lighter);
  cursor: default;
  pointer-events: none;
}

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  font-feature-settings: 'liga';
}

$nestedLevelMargin : 18px;